import axios from "axios";

const api_link = "https://testapi.dreamtrade.biz/super";
const access_key =
  "040ec1ee950ffc53291f6df0ffc30325-a1d41284cbad95a80f076eed39859381.77004ea213d5fc71acf74a8c9c6795fb";

const instance = axios.create({
  baseURL: api_link,
  timeout: 50000,
  headers: {
    "Content-Type": "application/json",
  },
});

export const GET = (url, config) => instance.get(url, config);
export const POST = (url, data, config) => {
  return instance.post(url, { ...data, token: access_key }, config);
};
