import React from "react";
import SoketContext from "../../../SocketContext/SocketContext";
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { NewTheme } from "../../Theme/Theme";
import NumberFormatIn from "../../Common/NumberFormatIn";

function HoldingCard(props) {
  const i = props.item;
  const socket = React.useContext(SoketContext);
  const [ask, setask] = useState(0);
  const [bid, setbid] = useState(0);
  const [pf, setpf] = useState(0);
  const [ltp, setltp] = useState(0);

  const sockettrade = (msg) => {
    if (ltp !== msg.LTP) {
      setltp(msg.LTP);
      if (props.item.net_type == 1) {
        let Profitloss = 0;
        Profitloss =
          (props.item.net_rate - msg.LTP) * Math.abs(props.item.net_qty);

        setpf(Profitloss);

        props.calc_pf(props.item.script_id, Profitloss, props.total);
      }
      if (props.item.net_type == 0) {
        let Profitloss2 = 0;
        Profitloss2 =
          (msg.LTP - props.item.net_rate) * Math.abs(props.item.net_qty);

        setpf(Profitloss2);
        props.calc_pf(props.item.script_id, Profitloss2, props.total);
      }
    }
  };

  useEffect(() => {
    socket.emit("giverate", props.item.script_id);
    socket.on("trade" + props.item.script_id, sockettrade);
    return () => {
      socket.off("trade" + props.item.script_id, sockettrade);
    };
  }, []);
  return (
    <div
      style={{
        height: 40,
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: "lightgray",
        backgroundColor: "#ffffff",
        display: "flex",
        alignItems: "center",
        paddingLeft: 10,
        fontWeight: "bolder",
        fontSize: 14,
      }}
    >
      <div
        style={{
          flex: 2,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          justifyContent: "space-between",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
          alignItems: "center",
          color: NewTheme.MainColor,
          alignItems: "center",
        }}
        onClick={() => props.modelwork(i.script_id, i.symbol)}
      >
        <span
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {i.symbol}{" "}
          <div
            style={{
              paddingLeft: 5,
              fontSize: 14,
              fontWeight: "bold",
              color: "gray",
            }}
          >
            {moment(i.expiry_date).format("DD-MMM-YY")}
          </div>
        </span>
        <span style={{ paddingRight: 10 }}>
          <i
            className="pi pi-info-circle"
            style={{
              fontSize: "1rem",
              color: "gray",
              alignSelf: "flex-start",
            }}
          ></i>
        </span>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
        }}
      >
        <NumberFormatIn value={i.buy_qty} fixed={2} />
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
        }}
      >
        {parseFloat(i.buy_avg).toFixed(2)}
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
        }}
      >
        <NumberFormatIn value={i.sell_qty} fixed={2} />
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
          color: NewTheme.MainColor,
        }}
      >
        {parseFloat(i.sell_avg).toFixed(2)}
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
        }}
      >
        {parseFloat(i.net_qty).toFixed(2)}
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
        }}
      >
        {parseFloat(ltp).toFixed(2)}
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
          color: pf > 0 ? NewTheme.greencolor : NewTheme.redcolor,
        }}
      >
        {parseFloat(pf).toFixed(2)}
      </div>
    </div>
  );
}

const MapStateToDispatch = (dispatch) => {
  return {
    calc_pf: (r, h, total) => {
      dispatch({ type: "PF3", script_id: r, pf: h, total: total });
    },
  };
};

export default connect(null, MapStateToDispatch)(HoldingCard);
