import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";

import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";

import { NewTheme } from "../../Theme/Theme";
import { BoldText, FlexContainer, FlexItem, GrayText } from "../Styled";
import NumberFormatIn from "../../Common/NumberFormatIn";
import { DataTable } from "primereact/datatable";
import { Column } from "jspdf-autotable";
import { InputText } from "primereact/inputtext";
import TopbarMost from "../../Common/TopbarMost";
import { FilterMatchMode } from "primereact/api";

const php = new Php();
const height = window.innerHeight;

export default function OverallProfitLossModal(props) {
  const { addNotification } = useNotification();
  const [loading, setLoading] = React.useState(false);
  const [tableData, setTableData] = React.useState([]);
  const [overallPl, setOverallPL] = React.useState(0);
  const [margin, setMargin] = React.useState(0);
  const [marginAmount, setMarginAmount] = React.useState(0);

  const [filters, setFilters] = React.useState({
    symbol: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  const [filtersGlobal, setFiltersGlobal] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const value = filtersGlobal["global"] ? filtersGlobal["global"].value : "";
  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filtersGlobal };

    _filters["global"].value = value;

    setFilters(_filters);
  };

  React.useEffect(() => {
    load_overall_pl();
  }, []);

  React.useEffect(() => {
    // Calculate the overall profit/loss
    let totalPL = tableData.reduce(
      (acc, transaction) => acc + transaction.overall_pl,
      0
    );

    // Update the state with the calculated value
    setOverallPL(parseFloat(totalPL).toFixed(0));
    setMarginAmount(
      (parseFloat(totalPL).toFixed(0) * parseFloat(margin)) / 100
    );
  }, [tableData]);

  const load_overall_pl = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        s_id: localStorage.getItem("supersId"),
      };

      php.load_overall_pl(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setTableData(r.transactions);
          setMargin(r.margin);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const filterbar = (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        paddingRight: 20,
        fontWeight: "bold",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 30,
              flexDirection: "column",
            }}
          >
            <div
              style={{
                fontSize: 16,
              }}
            >
              {"Profitloss "}
            </div>
            <div
              style={{
                fontSize: 16,
                marginTop: 3,
                color: overallPl >= 0 ? NewTheme.greencolor : NewTheme.redcolor,
              }}
            >
              <NumberFormatIn value={parseFloat(overallPl).toFixed(2)} />
            </div>
          </div>
          {/* <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 30,
              flexDirection: "column",
            }}
          >
            <div
              style={{
                fontSize: 16,
              }}
            >
              {"Margin : "}
              <span style={{ color: NewTheme.MainColor }}>
                {parseFloat(margin)}
              </span>
            </div>
            <div
              style={{
                fontSize: 16,
                marginTop: 3,
                color: overallPl >= 0 ? NewTheme.greencolor : NewTheme.redcolor,
              }}
            >
              <NumberFormatIn value={parseFloat(marginAmount)} />
            </div>
          </div> */}
        </div>
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 20,
        }}
      >
        <InputText
          style={{ width: 140 }}
          type="search"
          value={value || ""}
          onChange={(e) => onGlobalFilterChange(e)}
          placeholder="Search"
        />
      </div>
      {/* <Filter
        selectedUser={selectedUser}
        usersFilter={usersFilter}
        setSelectedUser={(e) => setSelectedUser(e)}
        date={date}
        setDate={(e) => setDate(e)}
        clearFilter={() => load_transactions("clear")}
        load_transactions={load_transactions}
      />
     */}
    </div>
  );

  const footerContent = (
    <div>
      <Button
        label="Close"
        icon="pi pi-times"
        onClick={() => props.setVisible(false)}
        className="p-button-text"
        style={{ color: NewTheme.MainColor }}
      />
    </div>
  );
  return (
    <Dialog
      header={
        <div style={{ color: NewTheme.MainColor }}>
          {"Overall ProfitLoss Summary "}
        </div>
      }
      visible={props.visible}
      position={props.position}
      style={{ width: "80vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      onHide={() => props.setVisible(false)}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      <>
        <TopbarMost background={"#ffffff"} noSidebar cmp={filterbar} />
        <DataTable
          removableSort
          stripedRows
          showGridlines
          scrollable
          scrollHeight={height - 120}
          size="small"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          value={!loading && tableData}
          style={{ fontWeight: "bold", fontSize: 14 }}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          globalFilterFields={["username", "symbol"]}
          paginator
          rows={15}
          loading={loading}
          filters={filters}
          onFilter={(e) => setFilters(e.filters)}
          rowsPerPageOptions={[10, 15, 30, 50, 100]}
        >
          <Column
            field="username"
            showFilterMenu={false}
            header="Username"
            sortable
            body={(rowData) => (
              <div
                style={{
                  fontWeight: "bold",
                  color:
                    rowData.status == 1
                      ? NewTheme.MainColor
                      : NewTheme.redcolor,
                }}
              >
                {rowData.username}
              </div>
            )}
          />

          <Column
            field="overall_pl"
            sortable
            showFilterMenu={false}
            header="Overall P/L"
            body={(rowData) => (
              <div
                style={{
                  fontWeight: "bold",
                  color:
                    rowData.overall_pl >= 0
                      ? NewTheme.greencolor
                      : NewTheme.redcolor,
                }}
              >
                <NumberFormatIn value={rowData.overall_pl} />
              </div>
            )}
          />
        </DataTable>
      </>
    </Dialog>
  );
}
