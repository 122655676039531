import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NewTheme } from "../Theme/Theme";

export const TopMenuContainer = styled.div`
  background-color: ${NewTheme.MainColor};
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const SubMenuContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  margin: 0 0.3rem; /* Increased margin for left and right */
  border: 1px solid transparent;
  transition: border 0.3s, transform 0.3s, color 0.3s, background-color 0.3s,
    border-radius 0.3s;
  background-color: transparent;

  svg {
    color: ${NewTheme.lightmain};
    margin-right: 0.25rem;
    transition: color 0.3s; /* Added color transition for the icon */
  }

  &:hover {
    border: 1px solid white;
    transform: scale(1.1);
    background-color: white;
    color: ${NewTheme.MainColor};
    border-radius: 4px;

    svg {
      color: ${NewTheme.MainColor}; /* Match icon color with text color on hover */
    }
  }

  &.selected {
    border: 1px solid white;
    background-color: white;
    color: ${NewTheme.MainColor};
    border-radius: 4px;

    svg {
      color: ${NewTheme.MainColor}; /* Match icon color with text color when selected */
    }
  }
`;

export const DropdownContainer = styled.div`
  position: relative;
  cursor: pointer;
`;

export const DropdownContent = styled.div`
  display: ${(props) => (props.open ? "block" : "none")};
  position: absolute;
  top: 100%;
  right: 0;
  background-color: ${NewTheme.MainColor};
  min-width: 160px;
  z-index: 1;
  border: 1px solid white;
  @media (max-width: 768px) {
    top: 3rem;
  }
`;

export const DropdownItem = styled.div`
  padding: 0.5rem;
  border-bottom: 1px solid white;
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background-color: white;
    cursor: pointer;
    color: ${NewTheme.MainColor};
    border: 1px solid ${NewTheme.MainColor};
  }
`;
