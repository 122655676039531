import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
import { ToWords } from "to-words";
import Php from "../../../../Backend/Php";
import { useNotification } from "../../../Notification/NotificationContext";
import { NewTheme } from "../../../Theme/Theme";
import { BalanceInput, BalanceLabel } from "../../Add/AddUser";
import { FlexItem } from "../Styled";

const php = new Php();

export default function LimitModal(props) {
  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });
  const [loading, setLoading] = React.useState(false);
  const { addNotification } = useNotification();
  const [balance, setBalance] = React.useState("");

  const balanceAdd = () => {
    if (
      isNaN(parseFloat(balance)) ||
      parseFloat(balance) == null ||
      parseFloat(balance) == "" ||
      parseFloat(balance) == undefined
    ) {
      addNotification("Enter Number Greater Than 0 ", "error");
    } else {
      if (!loading) {
        setLoading(true);

        let sendData = {
          sr: localStorage.getItem("server"),
          jwt: localStorage.getItem("token"),
          s_id: localStorage.getItem("supersId"),
          c_id: props.id,
          limit: parseFloat(balance).toFixed(2),
        };

        php.add_users_limit(sendData).then((r) => {
          setLoading(false);
          if (r.error === "False") {
            props.setVisible(false);
            props.reload();
            addNotification(r.message, "success");
          } else {
            addNotification(r.message, "error");
          }
        });
      }
    }
  };
  const footerContent = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        severity="danger"
        onClick={() => props.setVisible(false)}
      />
      <Button
        label="Add "
        icon="pi pi-plus"
        onClick={() => balanceAdd()}
        style={{ backgroundColor: NewTheme.MainColor }}
      />
    </div>
  );
  return (
    <Dialog
      header={
        <div style={{ color: NewTheme.MainColor }}>Change Total Limit</div>
      }
      visible={props.visible}
      position={props.position}
      style={{ width: "40vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      onHide={() => props.setVisible(false)}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      <FlexItem style={{ marginTop: 20 }}>
        <BalanceLabel>Total Limit</BalanceLabel>
      </FlexItem>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <BalanceInput
          type="number"
          value={balance}
          onChange={(e) => setBalance(e.target.value)}
          placeholder="Enter Limit Amount"
          style={{
            width: "100%",
          }}
          autoFocus
        />
        <div
          style={{
            fontWeight: "bold",
            marginBottom: 5,
            paddingLeft: 20,
            color: "gray",
          }}
        >
          {balance != "" ? toWords.convert(balance) : ""}
        </div>
      </div>
    </Dialog>
  );
}
