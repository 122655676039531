import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import { NotificationProvider } from "./Screens/Notification/NotificationContext";
import Notification from "./Screens/Notification/Notification";
import TopMenu from "./Screens/TopMenu/TopMenu";

// Import your components for different routes
import Home from "./Screens/Home/Home";
import Users from "./Screens/Users/Users";
import Login from "./Screens/Login/Login";
import LoginProvider from "./Screens/Login/LoginContext";
import Position from "./Screens/Position/Position";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { PrimeReactProvider } from "primereact/api";
import SocketContect from "./SocketContext/SocketContext";
import { connect, io } from "socket.io-client";
import Transaction from "./Screens/Transaction/Transaction";
import "primeicons/primeicons.css";
import Pending from "./Screens/Pending/Pending";
import { SidebarProvider } from "./Screens/Sidebar/SidebarContext";
import MySidebar from "./Screens/Sidebar/MySidebar";
import Brokers from "./Screens/Brokers/Brokers";
import Watchlist from "./Screens/Watchlist/Watchlist";
import Brokerage from "./Screens/Brokerage/Brokerage";
import Holding from "./Screens/Holding/Holding";
import Masters from "./Screens/Master/Masters";
import Profile from "./Screens/Profile/Profile";

var socket = io("https://emit.dreamtrade.biz");

const menuItems = [
  { name: "Home", component: Home },
  { name: "Masters", component: Masters },
  { name: "Users", component: Users },
  { name: "Positions", component: Position },
  { name: "Transactions", component: Transaction },
  { name: "Pendings", component: Pending },
  { name: "Brokers", component: Brokers },
  { name: "Watchlist", component: Watchlist },
  { name: "Brokerage", component: Brokerage },
  { name: "Holdings", component: Holding },
  { name: "Profile", component: Profile },
  // Add more routes for logged-in users as needed
];

function App() {
  socket.on("connect", () => {
    if (localStorage.getItem("isLoggedSuper") == "1") {
      var get_data = JSON.parse(localStorage.getItem("supersDetails"));

      socket.emit("login", {
        type: "Super",
        id: localStorage.getItem("supersId"),
        server: get_data.admin_id,
      });
    }
  });

  const isLogged = localStorage.getItem("isLoggedSuper") === "1";

  return (
    <div className="App">
      <SocketContect.Provider value={socket}>
        <SidebarProvider>
          <NotificationProvider>
            <LoginProvider>
              <PrimeReactProvider>
                <Router>
                  {isLogged && <MySidebar />}

                  <Routes>
                    {isLogged ? (
                      menuItems.map((menuItem) => (
                        <Route
                          key={menuItem.name}
                          path={`/${menuItem.name.toLowerCase()}`}
                          element={<menuItem.component />}
                        />
                      ))
                    ) : (
                      <Route path="login" element={<Login />} />
                    )}
                    <Route
                      path="*"
                      element={<Navigate to={isLogged ? "/home" : "/login"} />}
                    />
                  </Routes>
                </Router>
                <Notification />
              </PrimeReactProvider>
            </LoginProvider>
          </NotificationProvider>
        </SidebarProvider>
      </SocketContect.Provider>
    </div>
  );
}

export default App;
