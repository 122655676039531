import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import { NewTheme } from "../../Theme/Theme";
import {
  BoldText,
  FlexContainer,
  FlexItem,
  GrayText,
  GreenText,
} from "./Styled";
import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";

const php = new Php();

export default function LiveSummaryModal(props) {
  const { addNotification } = useNotification();
  const [activeSummary, setActiveSummary] = useState("");
  const [totalPL, setTotalPL] = useState("");
  const [marginFut, setMarginFut] = useState("");
  const [marginMcx, setMarginMcx] = useState("");
  const [marginFo, setMarginFo] = useState("");

  React.useEffect(() => {
    loadActiveSummary();
  }, [props.usersId]);

  const loadActiveSummary = () => {
    let data = {
      sr: localStorage.getItem("server"),
      jwt: localStorage.getItem("token"),
      s_id: localStorage.getItem("supersId"),
      m_id: props.usersId,
    };

    php.load_masters_active_summary(data).then((r) => {
      if (r.error == "False") {
        setActiveSummary(r.data);
        let PL = parseFloat(props.weekly_pl) + parseFloat(r.data);

        setTotalPL(parseFloat(PL).toFixed(0));
      } else {
        addNotification(r.message, "error");
      }
    });
  };

  const footerContent = (
    <div>
      <Button
        label="Close"
        icon="pi pi-times"
        onClick={() => props.setVisible(false)}
        className="p-button-text"
        style={{ color: NewTheme.MainColor }}
      />
      {/* <Button
        label="PDF"
        icon="pi pi-download"
        onClick={() => alert("PDF")}
        autoFocus
        style={{ backgroundColor: NewTheme.MainColor }}
      /> */}
    </div>
  );
  return (
    <Dialog
      header={
        <div style={{ color: NewTheme.MainColor }}>
          {"  Client Live Summary : "}
          {props.clientUsername != undefined ? props.clientUsername : null}
        </div>
      }
      visible={props.visible}
      position={props.position}
      style={{ width: "50vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      onHide={() => props.setVisible(false)}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      <>
        <FlexContainer>
          <FlexItem>
            <GrayText>Booked P/L</GrayText>
            <GreenText
              style={{
                color:
                  props.weekly_pl >= 0
                    ? NewTheme.greencolor
                    : NewTheme.redcolor,
              }}
            >
              {props.weekly_pl}
            </GreenText>
          </FlexItem>
          <FlexItem>
            <GrayText>Live P/L</GrayText>
            <GreenText
              style={{
                color:
                  activeSummary != null && parseFloat(activeSummary) > 0
                    ? NewTheme.greencolor
                    : NewTheme.redcolor,
              }}
            >
              {activeSummary == null ? 0 : parseFloat(activeSummary).toFixed(2)}
            </GreenText>
          </FlexItem>
          <FlexItem>
            <GrayText>Total P/L</GrayText>
            <GreenText
              style={{
                color:
                  totalPL != null && parseFloat(totalPL) > 0
                    ? NewTheme.greencolor
                    : NewTheme.redcolor,
              }}
            >
              {totalPL == null || isNaN(totalPL) ? 0 : parseFloat(totalPL)}
            </GreenText>
          </FlexItem>
        </FlexContainer>
      </>
    </Dialog>
  );
}
