import React from "react";
import { NumericFormat } from "react-number-format";
import { NewTheme } from "../Theme/Theme";

export default function NumberFormatIn(props) {
  return (
    <NumericFormat
      value={parseFloat(props.value).toFixed(props.fixed ? props.fixed : 0)}
      displayType={"text"}
      thousandSeparator={true}
      thousandsGroupStyle="lakh"
    />
  );
}
// Example usage:
// <NumberFormatIn value={1234567.89} thousandsGroupStyle="lakh" fixed={2} />
