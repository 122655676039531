import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import { Message } from "primereact/message";
import { TabPanel, TabView } from "primereact/tabview";
import React from "react";
import Php from "../../../Backend/Php";
import UpdateNotification from "../../Common/Notification";
import { NewTheme } from "../../Theme/Theme";
import { CreateClientButton } from "../../Brokers/Add/AddBroker";
import NotificationComponent from "./NotificationComponent";

const php = new Php();

export default function RightHomePanel(props) {
  const [notificationData, setNotificationData] = React.useState([]);
  const [notificationDataFilter, setNotificationDataFilter] = React.useState(
    []
  );
  const [onlineUsersData, setOnlineUsersData] = React.useState([]);
  const [countUsers, setCountUsers] = React.useState(0);

  const notificationTypes = [
    "Survelliance",
    "Limit Reached",
    "Force Sq Off",
    "Pending Pass",
  ];

  React.useEffect(() => {
    load_notification();
    load_online_users();
  }, []);

  const load_notification = () => {
    let data = {
      sr: localStorage.getItem("server"),
      jwt: localStorage.getItem("token"),
      s_id: localStorage.getItem("supersId"),
    };
    php.load_notification(data).then((r) => {
      if (r.error == "False") {
        setNotificationData(r.notification);
        setNotificationDataFilter(r.notification);
      } else {
        // alert(r.message);
      }
    });
  };

  const load_online_users = () => {
    let data = {
      sr: localStorage.getItem("server"),
      jwt: localStorage.getItem("token"),
      s_id: localStorage.getItem("supersId"),
    };
    php.load_online_users(data).then((r) => {
      if (r.error == "False") {
        setOnlineUsersData(r.online_users);
        setCountUsers(r.online_users.length);
      } else {
        // alert(r.message);
      }
    });
  };

  return (
    <TabView>
      <TabPanel style={{ color: NewTheme.MainColor }} header="NOTIFICATION">
        <div
          className="card"
          style={{ height: window.innerHeight - 150, overflowY: "scroll" }}
        >
          <NotificationComponent
            load_notification={() => load_notification()}
            notificationList={notificationData}
            notificationTypes={notificationTypes}
          />
        </div>
      </TabPanel>

      <TabPanel
        style={{ color: NewTheme.MainColor }}
        header={"ONLINE USERS (" + countUsers + ")"}
      >
        <div
          className="card"
          style={{ height: window.innerHeight - 150, overflowY: "scroll" }}
        >
          <div
            style={{
              display: "flex",
              padding: "5px 0px 5px 0px",
              padding: 5,
              flexDirection: "column",
              flexWrap: "wrap",
              borderColor: NewTheme.MainColor,
              fontWeight: "bold",
            }}
          >
            {onlineUsersData && onlineUsersData.length != 0
              ? onlineUsersData.map((i, t) => (
                  <>
                    <Message
                      style={{
                        width: "90%",
                        margin: 5,
                        justifyContent: "flex-start",
                        display: "flex",
                      }}
                      severity="success"
                      text={i.username}
                    />
                  </>
                ))
              : null}
          </div>
        </div>
      </TabPanel>
    </TabView>
  );
}
